$primary: #ea437a;
$secondary: #4269ab;

$white: #fff;
$black: #000;
$darkgray: #999;
$lightgray: #ccc;

$txt: #000;
$txtRevert: #fff;
$altTxt: #f8f6f0;
